import './index.css';
export default function Header() {
  return (
    <header class="header">
      <nav>
        <a href="/">
          <h1>
            Ben Wakefield
          </h1>
        </a>
        <div class="alt-pages">
          <a class="nav-link active" href="/">
            <img style={{width: 40}} class="nav-icons" src="icons/home.png"/>
          </a>
          <a class="nav-link" href="/cars">
            <img style={{width: 50}} class="nav-icons" src="icons/brz.png"/>
          </a>
          <a class="nav-link" href="/resume">
            <img style={{width: 40}} class="nav-icons" src="icons/gradhat.png"/>
          </a>
        </div>
      </nav>
    </header>
  );
}
import './index.css';
export default function Work() {
  return (
    <section class="work-wrapper">
      <section class="wrapper-wrapper">
        <section class="work">
          <section class="work-internal">
            <div id="SWE-title">
              <h1>
                Software Engineering Internship
              </h1>
              <h5>Cadence Design Systems</h5>
            </div>
            <div id="SWE-image">
              <img src="./work-images/cadence_test_graph.png" alt=''></img>
            </div>
            <br />
            <section id="SWE-desc">
              I was tasked with replacing an 8-year old Domain Specific Language Template Expander, a critical piece of
              infrastructure in the
              <span> </span>
              <span class="link-wrapper">
                <a
                  href="https://www.cadence.com/en_US/home/tools/silicon-solutions/compute-ip/technologies.html">Xtensa</a>
              </span>
              <span> </span>
              pipeline that was becoming deprecated. <br /><br />

              This expander took data from an XML file, did rule-checks against YAML rulesets, and utilized Templates
              written in a Domain Specific Language (re-invented by me!) to produce modified or conditional output in an
              internal Hardware Description Language called Sari.<br /><br />

              Important considerations in my work included language intuition, source readability, knowledge transfer, and
              seamless integration with an existing product.<br /><br />

              Many nuances had developed in the architecture and implementation of this component, which made it tricky to
              work around. In the end, I achieved 100% accuracy across
              <span class="highlight">300,000+ customer configurations</span>
              in regression testing using Jenkins and TestNG. My component is now
              <span class="highlight">live in production!</span>
              <br /><br />

              I worked with some amazing people. Thanks in particular to my supervisor Pete
              MacLiesh, mentor Kapil Thombare, and co-workers Ankit Sinha and Ben Jin. Besides these integral few, there
              were many friendly faces that I hope to work with again in the valley some day.<br /><br />Technologies
              <hr />
                <ul class="tech-hlist">
                  <li><a href="https://maven.apache.org/">Maven</a></li>
                  <li><a href="https://www.perforce.com/products/helix-core">Perforce</a></li>
                  <li><a href="https://www.jenkins.io/">Jenkins</a></li>
                  <li><a href="https://testng.org/">TestNG</a></li>
                  <li><a href="https://junit.org/junit5/">JUnit5</a></li>
                  <li>Java</li>
                  <li>Groovy</li>
                  <li>Bash</li>
                  <li>Eclipse</li>
                  <li>Vim</li>
                  <li>GNU/Linux</li>
                </ul>
            </section>
          </section>
        </section>
      </section>

    </section>
  );
}
import './index.css';
export default function Footer() {
  return (
    <footer class="footer">
      <section class="footer-container">
        <ul class="social-icons">
          <li>
            <a class="font-serif text-lg opacity-80 hover:opacity-100 underline"
              href="https://www.linkedin.com/in/benwkfld/" target="_blank" rel="noreferrer">
              <img src="./icons/linkedin.png" />
            </a>
          </li>
          <li>
            <a class="font-serif text-lg opacity-80 hover:opacity-100 underline" href="https://github.com/qedjdev"
              target="_blank" rel="noreferrer">
              <img src="./icons/github.png" />
            </a>
          </li>
          <li>
            <a class="font-serif text-lg opacity-80 hover:opacity-100 underline" href="https://instagram.com/nebwak"
              target="_blank" rel="noreferrer">
              <img src="./icons/instagram.png" />
            </a>
          </li>
          <li>
            <a class="font-serif text-lg opacity-80 hover:opacity-100 underline"
              href="https://discord.com/users/959233972242632714" target="_blank" rel="noreferrer">
              <img src="./icons/discord.png" />
            </a>
          </li>
          <li>
            <a class="font-serif text-lg opacity-80 hover:opacity-100 underline" href="mailto:ben@wakefield.fyi"
              target="_blank" rel="noreferrer">
              <img class="gmail" style={{marginBottom: -23}} src="./icons/gmail.png" />
            </a>
          </li>
        </ul>
      </section>
    </footer>
  );
}